import React from "react";
import highImpact from '../../assets/html/HighImpact.html';

export const HighImpacts =()=>{
  return (
    <div  style={{width:'100%',height:"100vh",marginTop:10,backgroundColor:'whitesmoke',
           overflowX:'scroll'}}>
      <div dangerouslySetInnerHTML={{ __html: highImpact }}></div>
    </div>
  );
};

