import React from "react";

 export const styleText = {
    textAlign:'center',
    paddingTop:'100px',
    width:'100%',
    height:'100%',
    fontWeight:'550',
    fontSize:'16px',
    backgroundColor:'#36454f',
    color:'white'
 }

 export const App_size = {
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
     flexDirection: 'column',
     width:'100%',
     height:'100%',
     paddingTop:10,
   }

 export const authContainer = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 2px 200px 20px white',
    width: '76%',
    height: '85%',
    backgroundColor:'inherit',
    overflow:'auto',
    fontSize:'16px'
 }

export const styleInput = {
    padding:'0px 20px 0px 20px',
    height: '17%',
    width:'95%',
    backgroundColor:'white',
    color:'black',
    fontSize: '16px',
    fontWeight: '550',
    fontFamily:'Verdana, Geneva, Tahoma, sans-serif',
  }

export const styleForm = {
    cursor:'pointer',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    rowGap:'1%',
    width:'90%',
    height:'90%',
    marginTop:10,
    paddingTop:10,
  }


export const controls = {
    position:'absolute',
    top:'10%',
    display: 'flex',
    width:'100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap:'7%'
  }

export const pdfCSS = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border:'white 1px thick',
    color:'black',
    borderRadius: '5px',
    width:'10%',
    height:'22%',
    fontsize: 'calc(1.0vw + 5px)',
    opacity:'0.8'
  }

  export const styleCSS = {
          textDecoration:'none',
          cursor:'pointer',
          border:'0.5px black solid',
          backgroundColor:'#36454f',
          padding:'8px', 
          color:'white',
          borderRadius:'20px',
          marginTop:'5px',
          marginBottom:'5px',
          boxShadow: '0 2px 2px 2px lab(23% -36 1)'
        }





  