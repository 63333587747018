import React,{useCallback} from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { RenderGoToPreviousPageProps, RenderGoToNextPageProps, 
    RenderGoToFirstPageProps, RenderGoToLastPageProps } from '@react-pdf-viewer/page-navigation';
import { attachmentPlugin } from '@react-pdf-viewer/attachment';
import { MoreActionsPopover } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin, ToolbarProps } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
//

export const renderToolbar = (Toolbar) => (
    <Toolbar>
        {(slots) => {
            const {
                CurrentPageInput, EnterFullScreen, GoToNextPage, GoToPreviousPage,
                NumberOfPages,ShowSearchPopover, Zoom, ZoomIn,
                ZoomOut, GoToFirstPage, GoToLastPage,Download,Print
            } = slots;

            return (
                <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:'space-around',
                    width: '100%',
                }}
            >
              <div style={{ padding: '0px 2px' }}>
                  <ShowSearchPopover />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <ZoomOut />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <Zoom />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <ZoomIn />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <GoToPreviousPage />
              </div>
              <div style={{ padding: '0px 2px', width: '4rem' }}>
                  <CurrentPageInput />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  / <NumberOfPages />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <GoToNextPage />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                  <EnterFullScreen />
              </div>
             {/*} <div style={{ padding: '0px 2px' }}>
                  <Download style={{display:'none'}} />
              </div>
              <div style={{ padding: '0px 2px' }}>
                  <Print />
            </div> */}
          </div>
         );
        }}
    </Toolbar>
    );