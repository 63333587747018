//
import React from "react";
import '../../styles/login.css';
import { App_size } from "./style";

    const alingOutput = {
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      flexDirection:'column',
      color:'white',
      marginTop:'10px',
      fontSize:'15px',
    }

    const authContainer = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 2px 200px 20px white',
    width: '76%',
    height: '85%',
    backgroundColor:'white',
    overflow:'auto',
    fontSize:'16px'
 }

export const Applications = () => {
 return(
            <div style={App_size}>
            <div style={authContainer}>
               <div style={alingOutput}>
                <h1 style={{color:'#36454f',fontWeight:'600',fontSize:'22px'}}>
                  Application StandBy List </h1>
                  <br/>
                   <h1 style={{color:'green',fontSize:'19px'}}>
                   Oracle : Anita du Preez ( 0662509609 )  </h1>
                   <br/>
                   <h1 style={{color:'green',fontSize:'19px'}}>
                   Mozaic :	Charmaine Govender ( 0737347434 )</h1>
                   <br/>
                   <h1 style={{color:'green',fontSize:'19px'}}>
                   NDC DBA: Beon Smal ( 083 690 5391 )</h1>
                   <br/>
                  <h1 style={{color:'green',fontSize:'19px'}}>
                   File and Print : Tshepo Mankga ( 065 999 1553 )</h1>
                  <br/>
                  <h1 style={{color:'green',fontSize:'19px'}}>
                   Exchange/O365 : 	Tawanda Hove ( 082 085 5313 )</h1>
               </div>
            </div>
            </div>
    );
  }

