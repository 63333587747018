import React from "react";
import roster from '../../assets/html/UpdatedRoster.html';

export const TimeSheet =()=>{
  return (
    <div  style={{marginTop:10,backgroundColor:'whitesmoke',cursor:'pointer'}}>
      <div dangerouslySetInnerHTML={{ __html: roster }}></div>
    </div>
  );
};

