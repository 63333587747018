import React from "react";
import breaks from '../../assets/html/Breaks.html';

export const Breaks = () => {
  return (
    <div  style={{width:"100vw",marginTop:10,backgroundColor:'whitesmoke',cursor:'pointer'}}>
      <div dangerouslySetInnerHTML={{ __html: breaks }}></div>
    </div>
  );
};

