import React from "react";

export const styleInput = {
    padding: '10px 20px 10px 20px',
    boxShadow: '0 4px 8px 3px black inset',
    margin: '0.3rem',
    height: '24.5%',
    width:'95%',
    backgroundColor:'azure',
    color:'black',
    fontSize: '20px',
    fontWeight: '550',
    fontFamily:'Verdana, Geneva, Tahoma, sans-serif',
  }

export const styleForm = {
    cursor:'pointer',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexWrap: 'wrap', /* */
    width:'100%',
    height:'100%',
  }

