import React from 'react';
import Login from './login/login';

function App() {

    return(
    <div> 
      <Login />
    </div>
    );

}

export default App;




