
export const controls = {
    position:'absolute',
    top:'10%',
    display: 'flex',
    width:'100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap:'7%'
  }

export const pdfCSS = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border:'white 1px thick',
    color:'black',
    borderRadius: '5px',
    width:'10%',
    height:'22%',
    fontsize: 'calc(1.0vw + 5px)',
    opacity:'0.8'
   }

  export const styleCSS = {
          textDecoration:'none',
          cursor:'pointer',
          border:'0.5px black solid',
          backgroundColor:'#36454f',
          padding:'8px', 
          color:'white',
          borderRadius:'20px',
          marginTop:'5px',
          marginBottom:'5px',
          boxShadow: '0 2px 2px 2px lab(23% -36 1)'
        }

 export const styleText = {
    textAlign:'center',
    paddingTop:'100px',
    width:'100%',
    height:'100%',
    fontWeight:'550',
    fontSize:'17px',
    backgroundColor:'#36454f',
    color:'white'
 }

 export const styleInput = {
    padding: '10px 20px 10px 20px',
    height: '25%',
    width:'100%',
    backgroundColor:'azure',
    color:'black',
    fontSize: '17px',
    fontWeight: '550',
    fontFamily:'Verdana, Geneva, Tahoma, sans-serif',
  }

export const styleForm = {
    cursor:'pointer',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    rowGap:'5%',
    width:'90%',
    height:'80%',
  }

 export const XY_size = {
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',
     flexDirection: 'column',
     width:'100%',
     height:'100%',
  }

 export const authContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid lab(21% -160 83)',
    boxShadow: '0 2px 200px 20px white',
    width: '70%',
    height: '50%',
    backgroundColor:'inherit'
  }


  