import React from "react";
import contacts from '../../assets/html/contacts.html';

export const IT_Contacts = () => {
  return (
    <div  style={{marginTop:10,backgroundColor:'whitesmoke',
    cursor:'pointer',backgroundColor:'white'}}>
      <div dangerouslySetInnerHTML={{ __html: contacts }}></div>
    </div>
  );
};

